import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import Cookies from 'js-cookie';

import { onError } from 'apollo-link-error';
import { logout } from '../hooks/consultant/useLogout';

const uploadLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
});
const retryLink = new RetryLink();
const authLink = setContext((_, { headers }) => {
  const token = Cookies.get('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  };
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    if ([401, 403].includes(networkError.statusCode)) {
      logout();
    }
  }
});

const state = {
  breadcrumbs: [],
  savedStatus: '',
  opportunityFilters: {
    __typename: 'OpportunityFilters',
    pageNumber: 1,
    tag: null,
    type: null,
    clients: null,
    status: null,
    isPrivate: false,
  },
  consultant: {
    __typename: 'Consultant',
    consultantId: '',
  },
};

const cache = new InMemoryCache();

function createApolloClient(_state, ctx) {
  return new ApolloClient({
    ssrMode: Boolean(ctx),
    link: ApolloLink.from([retryLink, authLink.concat(uploadLink), errorLink]),
    resolvers: {},
    cache,
  });
}
cache.writeData({ data: state });

export default createApolloClient;
